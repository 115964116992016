import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from '@mui/material/Divider';

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "vdx/components/MKButton";
import MKTypography from "vdx/components/MKTypography";

import colors from "vdx/assets/theme/base/colors"
import typography from "vdx/assets/theme/base/typography"

function ContactPage({ myRef }) {
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");

  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");

  function submit(e){
    e.preventDefault();
    e.stopPropagation();

    fetch("https://formcarry.com/s/F3gtNH86Evq", {
      method: 'POST',
      headers: { 
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ fullname, email, phoneNumber, message })
    })
    .then(response => response.json())
    .then(response => {
      if (response.code === 200) {
        setSubmitted(true);
      }
      else if(response.code === 422){
        // Field validation failed
        setError(response.message)
      }
      else {
        // other error from formcarry
        setError(response.message)
      }
    })
    .catch(error => {
      // request related error.
      setError(error.message ? error.message : error);
    });
  }

  return (
    <MKBox component="section" py={{ xs: 5, md: 15 }} ref={myRef}>
      <Container>
        <MKBox
          width="100%"
          mb={6}
        >
          <Grid container>
            <Grid item xs={12} lg={5}>
              <MKBox py={{ xs: 2, sm: 6 }}>
                <MKTypography variant="h1" mb={1} color="vdx_grey">
                  Contact
                </MKTypography>
                <MKTypography variant="body1" color="vdx_grey" mb={{ lg: 8 }}>
                  Pour toutes informations supplémentaires, contactez nous par email, téléphone ou utiliser notre formulaire de contact.
                </MKTypography>
                <Divider style={{ backgroundColor: "black" }} />
              </MKBox>
              <MKBox display="flex">
                <MKTypography color="vdx_green">
                  <i className="fas fa-phone" />
                </MKTypography>
                <MKTypography
                  color="vdx_grey"
                  fontWeight="bold"
                  ml={2}
                  component="a" 
                  href={"tel:+33786222026"} 
                  target="_blank"
                >
                  + 33 7 86 22 20 26
                </MKTypography>
              </MKBox>
              <MKBox display="flex" color="white" pt={3}>
                <MKTypography color="vdx_green">
                  <i className="fas fa-envelope" />
                </MKTypography>
                <MKTypography
                  color="vdx_grey"
                  fontWeight="bold"
                  ml={2}
                  component="a" 
                  href={"mailto:visiocytscreening@popsicube.com"} 
                  target="_blank"
                  style={{wordBreak: "break-all"}}
                >
                  visiocytscreening@popsicube.com
                </MKTypography>
              </MKBox>
            </Grid>
            <Grid
              item
              xs={12}
              lg={5}
              sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}
            >  
              <MKBox component="form" method="post" onSubmit={submit}>
                <Grid container>
                  <Grid item xs={12} pr={1} mb={1}>
                    <MKInput
                      variant="standard"
                      label="Nom Prénom"
                      fullWidth
                      InputLabelProps={{
                        style: {
                          ...typography.body1,
                          color: colors.vdx_grey.main
                        },
                      }}
                      InputProps={{ style: {height: 50 } }}
                      value={fullname}
                      onChange={(e) => setFullname(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} pr={1} mb={1}>
                    <MKInput
                      variant="standard"
                      label="Email"
                      fullWidth
                      InputLabelProps={{
                        style: {
                          ...typography.body1,
                          color: colors.vdx_grey.main
                        },
                      }}
                      InputProps={{ style: {height: 50 } }}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} pr={1} mb={1}>
                    <MKInput
                      variant="standard"
                      label="Téléphone"
                      fullWidth
                      InputLabelProps={{
                        style: {
                          ...typography.body1,
                          color: colors.vdx_grey.main
                        },
                      }}
                      InputProps={{ style: {height: 50 } }}
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} pr={1} mb={1}>
                    <MKInput
                      variant="standard"
                      label="Votre message"
                      fullWidth
                      multiline
                      rows={4}
                      InputLabelProps={{
                        style: {
                          ...typography.body1,
                          color: colors.vdx_grey.main
                        },
                      }}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </Grid>
                </Grid>
                <MKButton color="vdx_green" size="large" sx={{ mt: 2 }} type="submit">
                  Envoyer
                </MKButton>
                <Grid item xs={12} pr={1} mt={2} sx={{ fontSize: 9 }}>
                  Ces informations sont traitées par la société VitaDX pour répondre à vos demandes ainsi que pour promouvoir nos activités, 
                  sur la base de notre intérêt légitime lié à la bonne organisation de l’étude VisioCyt Screening et au développement de notre 
                  réseau de partenaires. Vos données ne sont accessibles qu’aux services habilités pour en connaître ainsi qu’à nos sous-traitants 
                  (hébergeur du site, société de recherche Popsicube, éditeurs de nos logiciels métiers). Elles sont conservées pendant 3 ans. 
                  Vous disposez des droits d’accès, de rectification et d’effacement vis-à-vis de vos données, ainsi que du droit de vous opposer à 
                  leur traitement et d’en demander la limitation. Pour les exercer, vous pouvez adresser une demande à notre dpo à l’adresse : dpo@vitadx.com. 
                  Vous disposez également du droit d’introduire une réclamation auprès de la CNIL.
                </Grid>
                { error &&
                  <p>{error}</p>
                }
                { submitted &&
                  <p>Vos informations ont bien été transmises, merci d'avoir pris contact !</p>
                }
              </MKBox>
            </Grid>
          </Grid>
        </MKBox>
      </Container>
    </MKBox>
  );
}

export default ContactPage;