/*
=========================================================
* Material Kit 2 PRO React - v2.1.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "vdx/components/MKButton";
import MKTypography from "vdx/components/MKTypography";

// Images
import logo from "vdx/assets/images/Logo VisioCyt-Screening_quadri_VF.svg";
import bgImage from "vdx/assets/images/vessie.svg";

import colors from "vdx/assets/theme/base/colors"

function Header({ contactRef, studyLink, investigationLink }) {
  const executeScrollToContact = () => contactRef.current.scrollIntoView()    

  return (
    <MKBox height="auto">
      <MKBox display="flex" alignItems="center" height="140px">
        <Container sx={{ display: "flex", justifyContent:"space-between" }} >
          <MKBox component="img" src={logo} sx={{ width: { xs: "210px", sm: "321px" }}} />
          <MKBox display="flex" alignItems="center">
            <MKButton color="vdx_grey" size="large" onClick={executeScrollToContact}>Contact</MKButton>
          </MKBox>
        </Container>
      </MKBox>
      <MKBox
        display="flex"
        alignItems="center"
        sx={{
          backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0),
              rgba(gradients.dark.state, 0)
            )}, url(${bgImage})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right -100px top 140px",
          backgroundColor: colors.vdx_green.main,
        }}
        py={{ xs: 5, md: 15 }}
      >
        <Container>
          <Grid container item xs={12} md={7} lg={6} flexDirection="column" justifyContent="center">
            <MKTypography
              variant="h1"
              color="white"
              mb={3}
            >
              Participer à une étude
              clinique pour le dépistage
              du cancer de la vessie
            </MKTypography>
            <MKTypography variant="body1" color="white" pr={6} mr={6}>
              En tant que médecin, rejoignez l'étude "VisioCyt Screening"
              pour faire avancer le dépistage précoce chez les patients
              asymptomatiques mais à haut risque de développer ce
              cancer
            </MKTypography>
            <Grid container alignItems="center" sx={{ mt:3 }}>
              <Grid item xs={12} lg={6}>
                <MKButton color="vdx_grey" size="large" onClick={() => window.open(studyLink, "_blank")}>
                  Participer à l'étude
                </MKButton>
              </Grid>
              <Grid item xs={12} lg={6}>
                <MKTypography
                  variant="body2"
                  fontWeight="regular"
                  color="white"
                  sx={{
                    width: "220px",
                    display: "flex",
                    alignItems: "center",
                    p: 1,
                
                    "& .material-icons-round": {
                      fontSize: "1.125rem",
                      transform: `translateX(3px)`,
                      transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                    },
                
                    "&:hover .material-icons-round, &:focus .material-icons-round": {
                      transform: `translateX(6px)`,
                    }
                  }}
                  component="a" href={investigationLink} target="_blank"
                >
                  Répondre à l’enquête sur le
                  cancer de la vessie <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                </MKTypography>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
    </MKBox>
  );
}

export default Header;
