// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import AboutUsOption from "vdx/components/AboutUsOption";
import MKTypography from "vdx/components/MKTypography";
import MKButton from "vdx/components/MKButton";

import gobeletIcon from "vdx/assets/images/ampoule.svg";
import euroIcon from "vdx/assets/images/euro.svg";
import rubanIcon from "vdx/assets/images/ruban.svg";
import médecinIcon from "vdx/assets/images/médecin.svg";

function WhyPage({ studyLink }) {
  return (
    <MKBox component="section" py={{ xs: 5, md: 15 }}>
      <Container>
        <MKTypography variant="h1" color="vdx_grey" my={1}>
          Pourquoi participer ?
        </MKTypography>
        <Grid container alignItems="center" mt={{ sm: 5 }} spacing={{ xs: 2, sm: 5 }}>
          <Grid item xs={12} md={6}>
            <MKBox
              display="flex"
              bgColor="white"
              borderRadius="xl"
              px={3}
              sx={{ boxShadow: 3, minHeight: 165 }}
            >
              <AboutUsOption
                icon={gobeletIcon}
                iconSize="3rem"
                content="Faire de votre cabinet un lieu d’innovation"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <MKBox
              display="flex"
              bgColor="white"
              borderRadius="xl"
              px={3}
              sx={{ boxShadow: 3, minHeight: 165 }}
            >
              <AboutUsOption
                icon={euroIcon}
                iconSize="3rem"
                content="Recevoir une rémunération par inclusion"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <MKBox
              display="flex"
              bgColor="white"
              borderRadius="xl"
              px={3}
              sx={{ boxShadow: 3, minHeight: 165 }}
            >
              <AboutUsOption
                icon={rubanIcon}
                iconSize="3rem"
                content="Permettre la mise en place d’une stratégie de dépistage du cancer de la vessie"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <MKBox
              display="flex"
              bgColor="white"
              borderRadius="xl"
              px={3}
              sx={{ boxShadow: 3, minHeight: 165 }}
            >
              <AboutUsOption
                icon={médecinIcon}
                iconSize="3rem"
                content="Permettre à vos patients d’être acteurs de la mise en place de ce dépistage par une simple analyse d’urine"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} mt={3}>
            <MKBox display="flex" justifyContent="center" mb={2}>
              Vous êtes intéressés par cette étude ?
            </MKBox>
            <MKBox display="flex" justifyContent="center">
              <MKButton color="vdx_green" size="large" onClick={() => window.open(studyLink, "_blank")}>
                Participer à l’étude
              </MKButton>
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default WhyPage;